<template>
  <div>
    <big-bgimg :list="bigData"></big-bgimg>
    <!-- 图片数据部分 -->
    <div class="tab-pane">
      <ul class="tab-pane-nav">
        <li
          :class="{ active: item.id === index }"
          class="M16"
          v-for="(item, i) in navList"
          :key="i"
          @click="change(item)"
        >
          {{ item.title }}
          <span class="line-span"></span>
        </li>
      </ul>
      <div class="tab-pane-content">
        <el-row>
          <el-col
            :span="8"
            v-for="item in imgShow.slice(
              (pagination.currentPage - 1) * pagination.pageSize,
              pagination.currentPage * pagination.pageSize
            )"
            :key="item.num"
          >
            <div class="img-div">
              <img
                ref="img"
                :src="gifImg === item.num ? item.gif : item.src"
                @mouseleave="gifImg = ''"
                @mouseenter="gifImg = item.num"
                alt=""
              />
            </div>
          </el-col>
        </el-row>
      </div>
      <!-- 分页 -->
      <el-pagination
        v-show="imgShow.length > 0"
        align="center"
        :background="true"
        @current-change="currentChange"
        :current-page="pagination.currentPage"
        :page-size="pagination.pageSize"
        layout=" prev, pager, next"
        :total="imgShow.length"
      >
      </el-pagination>
    </div>
    <!-- 试用 -->
    <try id="call"></try>
  </div>
</template>
<script>
import BigBgimg from "@/components/BigBgimg/index"; //大图
import Try from "@/components/Try/index";

export default {
  components: {
    BigBgimg,
    Try,
  },
  data() {
    return {
      // 大图数据
      bigData: {
        topTitle: "拓幻资源库",
        topText:
          "拓幻特效资源库集合所有资源特效，采用一种更方便的形式供用户预览及查看",
        topBtn: "联系我们",
        topImg: "",
        topBg: require("@/assets/home/resource/资源库首图.png"),
      },
      index: 1,
      navList: [
        {
          id: 1,
          title: "贴纸",
          imgArr: [
            {
              num: "1-1",
              src: require("@/assets/home/resource/贴纸/baerscarf.png"),
              gif: require("@/assets/home/resource/贴纸/baerscarf.gif"),
            },
            {
              num: "1-2",
              src: require("@/assets/home/resource/贴纸/blackcat.png"),
              gif: require("@/assets/home/resource/贴纸/blackcat.gif"),
            },
            {
              num: "1-3",
              src: require("@/assets/home/resource/贴纸/summer.png"),
              gif: require("@/assets/home/resource/贴纸/summer.gif"),
            },
            {
              num: "1-4",
              src: require("@/assets/home/resource/贴纸/bear.png"),
              gif: require("@/assets/home/resource/贴纸/bear.gif"),
            },
            {
              num: "1-5",
              src: require("@/assets/home/resource/贴纸/black_cloud.png"),
              gif: require("@/assets/home/resource/贴纸/black_cloud.gif"),
            },
            {
              num: "1-6",
              src: require("@/assets/home/resource/贴纸/bluebear.png"),
              gif: require("@/assets/home/resource/贴纸/bluebear.gif"),
            },
            {
              num: "1-7",
              src: require("@/assets/home/resource/贴纸/bride.png"),
              gif: require("@/assets/home/resource/贴纸/bride.gif"),
            },
            {
              num: "1-8",
              src: require("@/assets/home/resource/贴纸/buy.png"),
              gif: require("@/assets/home/resource/贴纸/buy.gif"),
            },
            {
              num: "1-9",
              src: require("@/assets/home/resource/贴纸/cat.png"),
              gif: require("@/assets/home/resource/贴纸/cat.gif"),
            },
            {
              num: "1-10",
              src: require("@/assets/home/resource/贴纸/chaiquan.png"),
              gif: require("@/assets/home/resource/贴纸/chaiquan.gif"),
            },

            {
              num: "1-11",
              src: require("@/assets/home/resource/贴纸/cigar.png"),
              gif: require("@/assets/home/resource/贴纸/cigar.gif"),
            },
            {
              num: "1-12",
              src: require("@/assets/home/resource/贴纸/cloud.png"),
              gif: require("@/assets/home/resource/贴纸/cloud.gif"),
            },
            {
              num: "1-13",
              src: require("@/assets/home/resource/贴纸/deer.png"),
              gif: require("@/assets/home/resource/贴纸/deer.gif"),
            },
            {
              num: "1-14",
              src: require("@/assets/home/resource/贴纸/demon.png"),
              gif: require("@/assets/home/resource/贴纸/demon.gif"),
            },
            {
              num: "1-15",
              src: require("@/assets/home/resource/贴纸/devil.png"),
              gif: require("@/assets/home/resource/贴纸/devil.gif"),
            },
            {
              num: "1-16",
              src: require("@/assets/home/resource/贴纸/dog.png"),
              gif: require("@/assets/home/resource/贴纸/dog.gif"),
            },
            {
              num: "1-17",
              src: require("@/assets/home/resource/贴纸/doggy.png"),
              gif: require("@/assets/home/resource/贴纸/doggy.gif"),
            },
            {
              num: "1-18",
              src: require("@/assets/home/resource/贴纸/huzi.png"),
              gif: require("@/assets/home/resource/贴纸/huzi.gif"),
            },
            {
              num: "1-19",
              src: require("@/assets/home/resource/贴纸/eyestar.png"),
              gif: require("@/assets/home/resource/贴纸/eyestar.gif"),
            },
            {
              num: "1-20",
              src: require("@/assets/home/resource/贴纸/flowerrabbit.png"),
              gif: require("@/assets/home/resource/贴纸/flowerrabbit.gif"),
            },
            {
              num: "1-21",
              src: require("@/assets/home/resource/贴纸/fadai.png"),
              gif: require("@/assets/home/resource/贴纸/fadai.gif"),
            },
            {
              num: "1-22",
              src: require("@/assets/home/resource/贴纸/glass.png"),
              gif: require("@/assets/home/resource/贴纸/glass.gif"),
            },
            {
              num: "1-23",
              src: require("@/assets/home/resource/贴纸/拍立得.png"),
              gif: require("@/assets/home/resource/贴纸/拍立得.png"),
            },
            {
              num: "1-24",
              gif: require("@/assets/home/resource/贴纸/eyemask.gif"),
              src: require("@/assets/home/resource/贴纸/eyemask.png"),
            },
            {
              num: "1-25",
              src: require("@/assets/home/resource/贴纸/hudiejie.png"),
              gif: require("@/assets/home/resource/贴纸/hudiejie.gif"),
            },
            {
              num: "1-26",
              src: require("@/assets/home/resource/贴纸/moustache.png"),
              gif: require("@/assets/home/resource/贴纸/moustache.gif"),
            },
            {
              num: "1-27",
              src: require("@/assets/home/resource/贴纸/kisses.png"),
              gif: require("@/assets/home/resource/贴纸/kisses.gif"),
            },
            {
              num: "1-28",
              src: require("@/assets/home/resource/贴纸/linerabbit.png"),
              gif: require("@/assets/home/resource/贴纸/linerabbit.gif"),
            },
            {
              num: "1-29",
              src: require("@/assets/home/resource/贴纸/maoer.png"),
              gif: require("@/assets/home/resource/贴纸/maoer.gif"),
            },
            {
              num: "1-30",
              src: require("@/assets/home/resource/贴纸/mickey.png"),
              gif: require("@/assets/home/resource/贴纸/mickey.gif"),
            },
            {
              num: "1-31",
              src: require("@/assets/home/resource/贴纸/mouse.png"),
              gif: require("@/assets/home/resource/贴纸/mouse.gif"),
            },
            {
              num: "1-32",
              src: require("@/assets/home/resource/贴纸/halloweeneve.png"),
              gif: require("@/assets/home/resource/贴纸/halloweeneve.gif"),
            },
            {
              num: "1-33",
              src: require("@/assets/home/resource/贴纸/nvwang.png"),
              gif: require("@/assets/home/resource/贴纸/nvwang.gif"),
            },
            {
              num: "1-34",
              src: require("@/assets/home/resource/贴纸/pick-me.png"),
              gif: require("@/assets/home/resource/贴纸/pick-me.gif"),
            },
            {
              num: "1-35",
              src: require("@/assets/home/resource/贴纸/rabbit.png"),
              gif: require("@/assets/home/resource/贴纸/rabbit.gif"),
            },
            {
              num: "1-36",
              src: require("@/assets/home/resource/贴纸/RedBow.png"),
              gif: require("@/assets/home/resource/贴纸/RedBow.gif"),
            },
            {
              num: "1-37",
              src: require("@/assets/home/resource/贴纸/sailorsuit.png"),
              gif: require("@/assets/home/resource/贴纸/sailorsuit.gif"),
            },
            {
              num: "1-38",
              src: require("@/assets/home/resource/贴纸/shopping.png"),
              gif: require("@/assets/home/resource/贴纸/shopping.gif"),
            },
            {
              num: "1-39",
              src: require("@/assets/home/resource/贴纸/small_bear.png"),
              gif: require("@/assets/home/resource/贴纸/small_bear.gif"),
            },
            {
              num: "1-40",
              src: require("@/assets/home/resource/贴纸/small_rabbit.png"),
              gif: require("@/assets/home/resource/贴纸/small_rabbit.gif"),
            },
            {
              num: "1-41",
              src: require("@/assets/home/resource/贴纸/stars_twinkle.png"),
              gif: require("@/assets/home/resource/贴纸/stars_twinkle.gif"),
            },
            {
              num: "1-42",
              src: require("@/assets/home/resource/贴纸/black_cat.png"),
              gif: require("@/assets/home/resource/贴纸/black_cat.gif"),
            },
            {
              num: "1-43",
              src: require("@/assets/home/resource/贴纸/tears.png"),
              gif: require("@/assets/home/resource/贴纸/tears.gif"),
            },
            {
              num: "1-44",
              src: require("@/assets/home/resource/贴纸/wangguan.png"),
              gif: require("@/assets/home/resource/贴纸/wangguan.gif"),
            },
            {
              num: "1-45",
              src: require("@/assets/home/resource/贴纸/wife.png"),
              gif: require("@/assets/home/resource/贴纸/wife.gif"),
            },
            {
              num: "1-46",
              gif: require("@/assets/home/resource/贴纸/winterhat.gif"),
              src: require("@/assets/home/resource/贴纸/winterhat.png"),
            },
            {
              num: "1-47",
              src: require("@/assets/home/resource/贴纸/wuyun.png"),
              gif: require("@/assets/home/resource/贴纸/wuyun.gif"),
            },
            {
              num: "1-48",
              src: require("@/assets/home/resource/贴纸/xiaobianzi.png"),
              gif: require("@/assets/home/resource/贴纸/xiaobianzi.gif"),
            },
            {
              num: "1-49",
              src: require("@/assets/home/resource/贴纸/yellow GLASS.png"),
              gif: require("@/assets/home/resource/贴纸/yellow GLASS.gif"),
            },
            {
              num: "1-50",
              src: require("@/assets/home/resource/贴纸/yutu.png"),
              gif: require("@/assets/home/resource/贴纸/yutu.gif"),
            },
            {
              num: "1-51",
              src: require("@/assets/home/resource/贴纸/zazhi.png"),
              gif: require("@/assets/home/resource/贴纸/zazhi.png"),
            },
            {
              num: "1-52",
              src: require("@/assets/home/resource/贴纸/铆钉狗狗.png"),
              gif: require("@/assets/home/resource/贴纸/铆钉狗狗.gif"),
            },
            {
              num: "1-53",
              src: require("@/assets/home/resource/贴纸/仓鼠.png"),
              gif: require("@/assets/home/resource/贴纸/仓鼠.gif"),
            },
            {
              num: "1-54",
              src: require("@/assets/home/resource/贴纸/圣诞帽.png"),
              gif: require("@/assets/home/resource/贴纸/圣诞帽.gif"),
            },
            {
              num: "1-55",
              src: require("@/assets/home/resource/贴纸/小狐狸.png"),
              gif: require("@/assets/home/resource/贴纸/小狐狸.gif"),
            },
            {
              num: "1-56",
              src: require("@/assets/home/resource/贴纸/GameGirl.png"),
              gif: require("@/assets/home/resource/贴纸/GameGirl.gif"),
            },
            {
              num: "1-57",
              src: require("@/assets/home/resource/贴纸/爱心.png"),
              gif: require("@/assets/home/resource/贴纸/爱心.gif"),
            },
            {
              num: "1-58",
              src: require("@/assets/home/resource/贴纸/猫耳朵.png"),
              gif: require("@/assets/home/resource/贴纸/猫耳朵.gif"),
            },
            {
              num: "1-59",
              src: require("@/assets/home/resource/贴纸/白色猫咪.png"),
              gif: require("@/assets/home/resource/贴纸/白色猫咪.gif"),
            },
            {
              num: "1-60",
              src: require("@/assets/home/resource/贴纸/线条猫耳朵.png"),
              gif: require("@/assets/home/resource/贴纸/线条猫耳朵.gif"),
            },
            {
              num: "1-61",
              src: require("@/assets/home/resource/贴纸/羊耳朵.png"),
              gif: require("@/assets/home/resource/贴纸/羊耳朵.gif"),
            },
            {
              num: "1-62",
              src: require("@/assets/home/resource/贴纸/草莓.png"),
              gif: require("@/assets/home/resource/贴纸/草莓.gif"),
            },
            {
              num: "1-63",
              src: require("@/assets/home/resource/贴纸/蓝猫.png"),
              gif: require("@/assets/home/resource/贴纸/蓝猫.gif"),
            },
            {
              num: "1-64",
              src: require("@/assets/home/resource/贴纸/贝雷帽.png"),
              gif: require("@/assets/home/resource/贴纸/贝雷帽.png"),
            },
            {
              num: "1-65",
              src: require("@/assets/home/resource/贴纸/zhaocaimao.png"),
              gif: require("@/assets/home/resource/贴纸/zhaocaimao.gif"),
            },
            {
              num: "1-66",
              src: require("@/assets/home/resource/贴纸/镜子.png"),
              gif: require("@/assets/home/resource/贴纸/镜子.png"),
            },
          ],
        },
        {
          id: 2,
          title: "互动",
          imgArr: [
            {
              num: "2-1",
              src: require("@/assets/home/resource/互动/rabbit.png"),
              gif: require("@/assets/home/resource/互动/rabbit.gif"),
            },
            {
              num: "2-2",
              src: require("@/assets/home/resource/互动/sweet.png"),
              gif: require("@/assets/home/resource/互动/sweet.gif"),
            },
            {
              num: "2-3",
              src: require("@/assets/home/resource/互动/tom.png"),
              gif: require("@/assets/home/resource/互动/tom.gif"),
            },
            {
              num: "2-4",
              src: require("@/assets/home/resource/互动/wawaji.png"),
              gif: require("@/assets/home/resource/互动/wawaji.gif"),
            },
            {
              num: "2-5",
              src: require("@/assets/home/resource/互动/土拨鼠.png"),
              gif: require("@/assets/home/resource/互动/土拨鼠.gif"),
            },
            {
              num: "2-6",
              src: require("@/assets/home/resource/互动/生气.png"),
              gif: require("@/assets/home/resource/互动/生气.gif"),
            },
            {
              num: "2-7",
              src: require("@/assets/home/resource/互动/葫芦娃.png"),
              gif: require("@/assets/home/resource/互动/葫芦娃.gif"),
            },
            {
              num: "2-8",
              src: require("@/assets/home/resource/互动/butterfly.png"),
              gif: require("@/assets/home/resource/互动/butterfly.gif"),
            },
            {
              num: "2-9",
              src: require("@/assets/home/resource/互动/dagongren.png"),
              gif: require("@/assets/home/resource/互动/dagongren.gif"),
            },
            {
              num: "2-10",
              src: require("@/assets/home/resource/互动/dragon.png"),
              gif: require("@/assets/home/resource/互动/dragon.gif"),
            },
            {
              num: "2-11",
              src: require("@/assets/home/resource/互动/hungry.png"),
              gif: require("@/assets/home/resource/互动/hungry.gif"),
            },
            {
              num: "2-12",
              src: require("@/assets/home/resource/互动/mermaid.png"),
              gif: require("@/assets/home/resource/互动/mermaid.gif"),
            },
            {
              num: "2-13",
              src: require("@/assets/home/resource/互动/nezha.png"),
              gif: require("@/assets/home/resource/互动/nezha.gif"),
            },
            {
              num: "2-14",
              src: require("@/assets/home/resource/互动/nvtuan.png"),
              gif: require("@/assets/home/resource/互动/nvtuan.gif"),
            },
            {
              num: "2-15",
              src: require("@/assets/home/resource/互动/panda.png"),
              gif: require("@/assets/home/resource/互动/panda.gif"),
            },
            {
              num: "2-16",
              src: require("@/assets/home/resource/互动/pikachu.png"),
              gif: require("@/assets/home/resource/互动/pikachu.gif"),
            },
          ],
        },
        /*  {
          id: 3,
          title: "面具",
          imgArr: [
            {
              num: "3-1",
              src: require("@/assets/home/resource/背抠gif/ganfan.png"),
              gif: require("@/assets/home/resource/背抠gif/ganfan.gif"),
            },
          ],
        }, */
        {
          id: 4,
          title: "礼物",
          imgArr: [
            {
              num: "4-1",
              src: require("@/assets/home/resource/礼物/520.png"),
              gif: require("@/assets/home/resource/礼物/520.gif"),
            },
            {
              num: "4-2",
              src: require("@/assets/home/resource/礼物/aixin.png"),
              gif: require("@/assets/home/resource/礼物/aixin.gif"),
            },
            {
              num: "4-3",
              src: require("@/assets/home/resource/礼物/chengbao.png"),
              gif: require("@/assets/home/resource/礼物/chengbao.gif"),
            },
            {
              num: "4-4",
              src: require("@/assets/home/resource/礼物/Diss.png"),
              gif: require("@/assets/home/resource/礼物/Diss.gif"),
            },
            {
              num: "4-5",
              src: require("@/assets/home/resource/礼物/jinzhuan.png"),
              gif: require("@/assets/home/resource/礼物/jinzhuan.gif"),
            },
            {
              num: "4-6",
              src: require("@/assets/home/resource/礼物/kaixinle.png"),
              gif: require("@/assets/home/resource/礼物/kaixinle.gif"),
            },
            {
              num: "4-7",
              src: require("@/assets/home/resource/礼物/maozhua.png"),
              gif: require("@/assets/home/resource/礼物/maozhua.gif"),
            },
            {
              num: "4-8",
              src: require("@/assets/home/resource/礼物/meihua.png"),
              gif: require("@/assets/home/resource/礼物/meihua.gif"),
            },
            {
              num: "4-9",
              src: require("@/assets/home/resource/礼物/perfume.png"),
              gif: require("@/assets/home/resource/礼物/perfume.gif"),
            },
            {
              num: "4-10",
              src: require("@/assets/home/resource/礼物/rose.png"),
              gif: require("@/assets/home/resource/礼物/rose.gif"),
            },
            {
              num: "4-11",
              src: require("@/assets/home/resource/礼物/semimi.png"),
              gif: require("@/assets/home/resource/礼物/semimi.gif"),
            },
            {
              num: "4-12",
              src: require("@/assets/home/resource/礼物/yijianchuanxin.png"),
              gif: require("@/assets/home/resource/礼物/yijianchuanxin.gif"),
            },
            {
              num: "4-13",
              src: require("@/assets/home/resource/礼物/yuanbao.png"),
              gif: require("@/assets/home/resource/礼物/yuanbao.gif"),
            },
            {
              num: "4-14",
              src: require("@/assets/home/resource/礼物/两颗爱心.png"),
              gif: require("@/assets/home/resource/礼物/两颗爱心.gif"),
            },
            {
              num: "4-15",
              src: require("@/assets/home/resource/礼物/奖杯.png"),
              gif: require("@/assets/home/resource/礼物/奖杯.gif"),
            },
            {
              num: "4-16",
              src: require("@/assets/home/resource/礼物/火箭.png"),
              gif: require("@/assets/home/resource/礼物/火箭.gif"),
            },
            {
              num: "4-17",
              src: require("@/assets/home/resource/礼物/爱心.png"),
              gif: require("@/assets/home/resource/礼物/爱心.gif"),
            },
            {
              num: "4-18",
              src: require("@/assets/home/resource/礼物/玫瑰花.png"),
              gif: require("@/assets/home/resource/礼物/玫瑰花.gif"),
            },
          ],
        },
        /* {
          id: 5,
          title: "绿幕抠图",
          imgArr: [
            {
              num: "5-1",
              src: require("@/assets/home/resource/背抠gif/ganfan.png"),
              gif: require("@/assets/home/resource/背抠gif/ganfan.gif"),
            },
          ],
        }, */
        {
          id: 6,
          title: "人像抠图",
          imgArr: [
            {
              num: "6-1",
              src: require("@/assets/home/resource/背抠gif/ganfan.png"),
              gif: require("@/assets/home/resource/背抠gif/ganfan.gif"),
            },
            {
              num: "6-2",
              src: require("@/assets/home/resource/背抠gif/hongbao.png"),
              gif: require("@/assets/home/resource/背抠gif/hongbao.gif"),
            },
            {
              num: "6-3",
              src: require("@/assets/home/resource/背抠gif/nihong.png"),
              gif: require("@/assets/home/resource/背抠gif/nihong.gif"),
            },
            {
              num: "6-4",
              src: require("@/assets/home/resource/背抠gif/niunian.png"),
              gif: require("@/assets/home/resource/背抠gif/niunian.gif"),
            },
            {
              num: "6-5",
              src: require("@/assets/home/resource/背抠gif/nvshen.png"),
              gif: require("@/assets/home/resource/背抠gif/nvshen.gif"),
            },
            {
              num: "6-6",
              src: require("@/assets/home/resource/背抠gif/pengke.png"),
              gif: require("@/assets/home/resource/背抠gif/pengke.gif"),
            },
            {
              num: "6-7",
              src: require("@/assets/home/resource/背抠gif/pukepai.png"),
              gif: require("@/assets/home/resource/背抠gif/pukepai.gif"),
            },
            {
              num: "6-8",
              src: require("@/assets/home/resource/背抠gif/xiannv.png"),
              gif: require("@/assets/home/resource/背抠gif/xiannv.gif"),
            },
            {
              num: "6-9",
              src: require("@/assets/home/resource/背抠gif/xiaozhan.png"),
              gif: require("@/assets/home/resource/背抠gif/xiaozhan.gif"),
            },
            {
              num: "6-10",
              src: require("@/assets/home/resource/背抠gif/youleyuan.png"),
              gif: require("@/assets/home/resource/背抠gif/youleyuan.gif"),
            },
            {
              num: "6-11",
              src: require("@/assets/home/resource/背抠gif/背抠兔子.png"),
              gif: require("@/assets/home/resource/背抠gif/背抠兔子.gif"),
            },
            {
              num: "6-12",
              src: require("@/assets/home/resource/背抠gif/胡萝卜.png"),
              gif: require("@/assets/home/resource/背抠gif/胡萝卜.gif"),
            },
          ],
        },
        {
          id: 7,
          title: "手势识别",
          imgArr: [
            {
              num: "7-1",
              src: require("@/assets/home/resource/手势/guanzhao.png"),
              gif: require("@/assets/home/resource/手势/guanzhao.gif"),
            },
            {
              num: "7-2",
              src: require("@/assets/home/resource/手势/happy.png"),
              gif: require("@/assets/home/resource/手势/happy.gif"),
            },
            {
              num: "7-3",
              src: require("@/assets/home/resource/手势/heart.png"),
              gif: require("@/assets/home/resource/手势/heart.gif"),
            },
            {
              num: "7-4",
              src: require("@/assets/home/resource/手势/maoer.png"),
              gif: require("@/assets/home/resource/手势/maoer.gif"),
            },
            {
              num: "7-5",
              src: require("@/assets/home/resource/手势/桃花.png"),
              gif: require("@/assets/home/resource/手势/桃花.gif"),
            },
          ],
        },
      ],
      imgShow: [],
      pagination: {
        currentPage: 1,
        pageSize: 6,
      },
      gifImg: "", //png换gif
    };
  },
  created() {
    this.imgShow = this.navList[0].imgArr;
  },

  beforeDestroy() {
    clearTimeout(this.timer);
  },
  methods: {
    // 分类导航点击
    change(item) {
      this.index = item.id;
      this.pagination.currentPage = 1;
      this.imgShow = item.imgArr;
    },
    // 分页
    currentChange(val) {
      this.pagination.currentPage = val;
    },
  },
};
</script>
<style lang="scss">
.el-pagination.is-background {
  .btn-prev,
  .btn-next {
    background-color: #fff;
    color: #222;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    &:not(:disabled):hover {
      color: #409eff;
      border: 1px solid rgb(138, 193, 249);
    }
  }
  .el-pager li:not(.disabled) {
    background-color: #fff;
    color: #222222;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    font-family: "SourceHanSansCN-Normal";
    font-weight: 400;
    font-size: 14px;
    &:hover {
      border: 1px solid rgb(138, 193, 249);
    }
  }
  .el-pager li:not(.disabled).active {
    background-color: #008cff;
    color: #fff;
  }
}
</style>

<style lang="scss" scoped>
.tab-pane {
  height: 828px;
  .tab-pane-nav {
    height: 120px;
    display: -ms-flex;
    display: -moz-flex;
    display: -webkit-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    li {
      color: #666666;
      line-height: 55px;
      margin-right: 60px;
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }
      .line-span {
        display: block;
        width: 0px;
        margin: auto;
        height: 2px;
        background: #4c8cf5;
        transition: 0.5s;
      }
    }

    .active {
      color: #4c8cf5;
      .line-span {
        display: block;
        width: 20px;
      }
    }
  }
  .tab-pane-content {
    height: 616px;
    text-align: center;
    .el-row {
      width: 1440px;
      margin: auto;
    }
    img {
      width: 420px;
      height: 260px;
      border-radius: 12px;
      transition: all 1s;
      cursor: pointer;
      &:hover {
        transform: scale(1.1);
      }
    }
    .img-div {
      width: 420px;
      margin: auto;
      height: 260px;
      overflow: hidden;
      border-radius: 12px;
      box-shadow: 0px 0px 18px 0px rgba(180, 180, 180, 0.46);
      &:hover {
        box-shadow: 0px 0px 18px 0px rgba(138, 193, 249, 0.4);
      }
    }
    .el-col {
      margin-bottom: 40px;
    }
  }
}
</style>
